<template>
  <div id="disabled" v-if="!$store.state.auth.active">
    {{ $t("no_active") }}
    {{
      $store.state.auth.active_date
        ? $t("will_active", {
            date: $d(new Date($store.state.auth.active_date), "date2digits"),
          })
        : ""
    }}
  </div>
</template>

<script>
export default {
  name: "PagesCoreAppDisabled",
  data: () => ({}),
  components: {},
};
</script>
<style scoped lang="sass">
#disabled
  text-align: center
  background-color: var(--v-login_error-base)
  z-index: 15
  position: fixed
  left: 0
  right: 0
  top: 0
  height: 30px
  line-height: 30px
</style>
